<template>
  <v-container fluid id="regular-table">
    <div class="ma-3 d-flex flex-column flex-md-row" style="gap: 30px">
      <v-flex xs6>
        <h3 class="text-h4 mb-4">{{ $t("transfer_details") }}</h3>
        <div class="ma-4">
          <div class="text-h4 d-flex justify-space-between py-4">
            <span>{{ $t("origin_hub") }}</span>
            <span class="text-h4">
              {{ transfer?.fromHub?.name ?? "-" }}
            </span>
          </div>
          <v-divider></v-divider>
          <div class="text-h4 d-flex justify-space-between py-4">
            <span>{{ $t("destination_hub") }}</span>
            <span class="text-h4">
              {{ transfer?.toHub?.name ?? "-" }}
            </span>
          </div>

          <v-divider></v-divider>
          <div
            class="text-h4 d-flex justify-space-between py-4"
            style="min-width: 200px"
          >
            <span class="mt-2">{{ $t("transfer_note") }}</span>
            <v-btn
              small
              :loading="isLoading"
              rounded
              class="primary"
              text
              @click="downloadDeliveryNot(transfer)"
            >
              {{ "transfer-note-" + transfer.tracking_number + ".pdf" }}
              <v-icon class="ma-2"> mdi-download </v-icon>
            </v-btn>
          </div>
          <template v-if="transfer?.transferStatus?.name != 'open'">
            <v-divider></v-divider>
            <div class="text-h4 d-flex justify-space-between py-4">
              <span>{{ $t("status") }}</span>
              <span class="text-h4">
                {{ transfer?.transferStatus?.name.toUpperCase() ?? "-" }}
              </span>
            </div>
          </template>
          <v-divider></v-divider>
          <div class="text-h4 d-flex justify-space-between py-4">
            <span>{{ $t("delivery_date") }}</span>
          </div>

          <div class="d-flex flex-row align-center">
            <span class="text-h4 mr-auto">
              <v-icon> mdi-calendar </v-icon>
              {{ transfer.expected_delivery_date }}
            </span>
            <dir class="ml-2">
              <label class="mx-2"
                >{{ $t("last_edited") + " : " + transfer.updated_at }}
              </label>
              <a text @click="show_details = !show_details">
                {{ show_details ? $t("hide_details") : $t("show_details") }}
                <v-icon>{{
                  show_details ? "mdi-chevron-up" : "mdi-chevron-down"
                }}</v-icon>
              </a>
            </dir>
          </div>
          <v-expand-transition>
            <v-col cols="8" v-show="show_details">
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">{{ $t("previous_date") }}</th>
                      <th class="text-left">{{ $t("edit_date") }}</th>
                      <th class="text-left">{{ $t("modified_by") }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="history in transfer.history"
                      :key="history.previous_date"
                    >
                      <td>{{ history.previous_date }}</td>
                      <td>{{ history.edit_date }}</td>
                      <td>{{ history.modified_by }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-expand-transition>
          <v-divider class="my-4"></v-divider>
        </div>
      </v-flex>
    </div>
    <div>
      <v-card>
        <v-card-title class="text-h4">
          Item List ({{ transfer.products_count }})
        </v-card-title>
        <br />
        <div class="ms-5">
          <v-data-table
            :items="transfer.products"
            :headers="headers"
            item-key="id"
            class="elevation-0"
          >
            <template v-slot:item.image="{ item }">
              <v-img
                v-if="item.image"
                :src="item.image.url"
                max-height="50"
                max-width="50"
              />
              <v-img
                v-else
                src="@/assets/product.png"
                max-height="50"
                max-width="50"
              />
            </template>

            <template v-slot:item.purchase_price="{ item }">
              {{
                Number(
                  item.purchase_price_ht *
                    (1 + parseFloat(item.purchase_tva) / 100)
                )?.toFixed(2)
              }}
            </template>
          </v-data-table>
        </div>
      </v-card>
      <validate-reception-modal
        v-if="isValidateReceptionModalOpen"
        :isModalOpen="isValidateReceptionModalOpen"
        :transfer="transfer"
        :toggleModal="() => toggleValidateReceptionModal()"
      >
      </validate-reception-modal>
      <div class="mt-8 d-flex">
        <v-btn outlined class="ml-auto primary--text" @click="close()">
          Back
        </v-btn>
        <v-btn color="primary ml-4" @click="validateReception(transfer)">
          {{ $t("validate") }}
        </v-btn>
      </div>
    </div>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import transferService from "@/store/services/transfer-product-service";

export default {
  components: {
    ValidateReceptionModal: () => import("./modals/ValidateReceptionModal.vue"),
  },
  computed: {
    ...mapGetters({
      transfer: "transferProducts/transfer",
    }),
  },

  data() {
    return {
      isLoading: false,
      show_details: false,
      isValidateReceptionModalOpen: false,

      headers: [
        { text: this.$t("image"), value: "image", align: "start" },
        {
          text: this.$t("name"),
          sortable: false,
          value: "name",
        },
        { text: this.$t("quantity"), value: "qte_in_transfer" },
        { text: this.$t("purchase_price_ht"), value: "purchase_price_ht" },
        { text: this.$t("tva"), value: "purchase_tva" },
        { text: this.$t("purchase_price"), value: "purchase_price" },
      ],
      date_headers: [
        { text: "Previous date", value: "previous_date" },
        { text: "Edit date", value: "edit_date" },
        { text: "Modified by", value: "modified_by" },
      ],
    };
  },

  methods: {
    async downloadDeliveryNot(transfer) {
      this.isLoading = true;
      const BDC = await transferService.getDeliveryNote(transfer);
      const url = window.URL.createObjectURL(new Blob([BDC]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${transfer.tracking_number}.pdf`);
      document.body.appendChild(link);
      link.click();
      this.isLoading = false;
    },

    close() {
      this.$store.commit("SET_PAGE_SUBTITLE", "");
      this.$store.commit("SET_SUBTITLE_ID", "");

      this.$router.push("/transfers");
    },

    toggleValidateReceptionModal() {
      this.isValidateReceptionModalOpen = !this.isValidateReceptionModalOpen;
    },

    validateReception(item) {
      this.isValidateReceptionModalOpen = true;
      this.currentTransfer = item;
    },
  },

  async created() {
    const id = this.$route.params.id;
    if (id) {
      const transferEntity = await transferService.show({
        id,
      });

      this.$store.commit("transferProducts/TRANSFER", transferEntity);
      this.$store.commit("SET_PAGE_SUBTITLE", transferEntity.tracking_number);
      this.$store.commit("SET_SUBTITLE_ID", "Transfer ID :");
    }
  },
};
</script>